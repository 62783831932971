import { environment } from '@env/environment';
import { PermissionService } from '../../shared/services/permission.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

import { Router, NavigationStart } from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';
import { UserService } from '../../auth0/services/user.service';
import { prefetchData } from '../../../generated/prefetchData';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SponsorsModalComponent } from '../components/sponsors-modal/sponsors-modal.component';
import { MobileAppModalComponent } from '../components/mobile-app-modal/mobile-app-modal.component';
import { Roles } from '../../../generated/globalTypes';
import { UserFull } from '../../../generated/UserFull';
import { MixpanelService } from '../../shared/services/mixpanel.service';
import { KycAnswersService } from '../../../app/shared/services/kyc-answers.service';
import { TaskService } from '../../user/services/task.service';
import { BundleNotifactionsService } from '../../shared/services/bundle-notifcation.service';
import { ThemeService } from '../../shared/services/theme.service';
import { Theme } from '../../../generated/Theme';
import { TokenValidatorService } from "../../shared/services/token-validator.service";

@Component({
  selector: 'cok-app',
  templateUrl: './app.html',
  styleUrls: ['./app.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly subscription = new Subject<void>();

  public loggedIn$: Observable<boolean>;

  public currentUser$: Observable<UserFull | undefined>;
  public isAdmin$: Observable<boolean>;
  public roles = Roles;
  public casesPage: boolean;
  public faSortDown = faSortDown;

  public hasUnfinishedTasks = false;
  public loadedTheme = false;

  public canManageTeams = false;

  public theme: Theme | undefined;

  constructor(
    public router: Router,
    private permissionService: PermissionService,
    private kycService: KycAnswersService,
    private bundleNotificationService: BundleNotifactionsService,
    private userService: UserService,
    private ngxModalService: BsModalService,
    private apollo: Apollo,
    private mixpanelService: MixpanelService,
    private taskService: TaskService,
    private themeService: ThemeService,
    private tokenValidatorService: TokenValidatorService
  ) {
    // Init Ngx-Bootstrap
    setTheme('bs4');

    this.loggedIn$ = this.userService.isLoggedIn$;
    this.currentUser$ = this.userService.currentUser$;
    this.isAdmin$ = this.userService.isAdmin();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.subscription)
      )
      .subscribe((event) => {
        const { url } = event as NavigationStart;
        this.casesPage = !url.includes('team') && !url.includes('admin') && !url.includes('user/profile') && !url.includes('reports') && !url.includes('tasks');
      });

    this.mixpanelService.trackIntercomEvent('fc-session-start');
    this.subscribe();
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  subscribe() {
    this.loggedIn$.subscribe((loggedIn) => {
      if (!loggedIn) return;

      this.tokenValidatorService.trackMfaState();

      this.mixpanelService.trackAction('logged-in');

      // subscribe to all schema data to preload it in the apollo cache
      this.apollo
        .query<prefetchData>({
          query: gql`
            query prefetchData {
              schema {
                caseStatus {
                  id
                  name
                  representsClosure
                  sortOrder
                }
                childStatus {
                  id
                  name
                  sortOrder
                }
                relationshipStatus {
                  id
                  name
                  colorBlind
                  color
                  sortOrder
                }
                salaryRange {
                  id
                  label
                  values
                }
                auditLogActions
                engagementTypes
              }
              addressCountries {
                id
                name
                countryCode
              }
              # 233 corresponds to USA
              addressStates(countryId: 233) {
                id
                name
                stateCode
              }
            }
          `,
          variables: {},
        })
        .pipe(takeUntil(this.subscription))
        .subscribe();

        this.taskService.hasUnfinishedTasks()
          .pipe(takeUntil(this.subscription))
          .subscribe((result) => this.hasUnfinishedTasks = result || false);

      this.themeService.getSelectedTheme()
        .pipe(takeUntil(this.subscription))
        .subscribe({
          next: (theme) => {
            this.theme = theme;
            this.loadedTheme = true;
          },
          error: () => this.loadedTheme = true
        })

      if (this.router.url.indexOf('/login') >= 0) {
        this.router.navigate(['/']).then();
      }
    });
  }

  onAlertIconClick(){
    this.mixpanelService.trackAction('tasks-alert-icon-click');
  }

  logout() {
    this.userService.logout();
  }

  public reportsClicked() {
    this.mixpanelService.trackAction('reports-click');
  }

  public showPoweredByModal(_event: any) {
    this.ngxModalService.show(SponsorsModalComponent, {
      class: 'modal-lg modal-dialog-centered',
    });
  }

  public showMobileAppModal(_event: any) {
    this.ngxModalService.show(MobileAppModalComponent, {
      class: 'modal-lg modal-dialog-centered',
    });
  }

  public checkTeamPermissions() {
    if (!this.userService.currentUser?.userTeams.length) {
      return false;
    }

    let hasTeamPermissions = false;
    this.userService.currentUser?.userTeams.forEach((team) => {
      if (team.role !== "NONE") {
        hasTeamPermissions = true;
      }
    })

    return hasTeamPermissions
  }
}
