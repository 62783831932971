import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { exhaustMap, share, take } from 'rxjs/operators';
import { WebAuth, Auth0DecodedHash, CheckSessionOptions } from 'auth0-js';
import { CookieService } from 'ngx-cookie-service';
import { LetsMFAService, CheckResponse } from 'lets-mfa-angular';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';

interface Token {
  accessToken: string;
  idToken: string;
  expiresAt: number;
  logoutRedirectUrl: string;
}

const SCOPE = 'openid profile email everything';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _token: Token | undefined;
  private _token$ = new BehaviorSubject<Token | undefined>(undefined);
  private _webAuth: WebAuth | undefined;

  constructor(
    private letsMFAService: LetsMFAService,
    private cookieService: CookieService,
    private http: HttpClient,
    private configService: ConfigService,
    private router: Router
    ) {}

  public async handleAuthentication() {

    const webAuth = await this.getWebAuth();

    const self = this;

    webAuth.parseHash({ __enableIdPInitiatedLogin: true }, async (err, authResult) => {
      if (err) {

        if(err.errorDescription?.includes('verify your email')) {
          this.router.navigate(['/verify-email']);
          return;
        }

        console.error(`error parsing auth0 hash`);
        console.error(err);
        await this.login();
        return;
      }

      if (authResult == null) {
        console.error('No authResult returned from auth0 library');
        await this.login();
        return;
      }

      // When the accessToken returned is not a JWT
      if (authResult.accessToken?.includes('..')) {
        try {
          const authResult2 = await this.checkSession({
            scope: SCOPE,
            prompt: 'none',
          });
          this.handleSession(authResult2);
        } catch (err: any) {

          if(err.description?.includes('verify your email')) {
            this.router.navigate(['/verify-email']);
            return;
          }

          console.error(`error checking session`);
          console.error(err);
          webAuth.authorize({});
          return;
        }
      } else {
        this.handleSession(authResult);
      }

      this.letsMFAService.checkValidationState({
        headers: {
            Authorization: `Bearer ${this._token?.accessToken}`,
          }
      }).subscribe({
        next: (state) => {
          if(state.validationState != "validated")
            this.startMfaEnrollOrAuth();
          else {
            this.handleSession(authResult);
            this.router.navigate(['/dashboard']);
          }
        },
        error: (err) => {
          console.error(err);
          this.startMfaEnrollOrAuth();
        }
      });

      
    });
  }

  private async startMfaEnrollOrAuth() {
    this.letsMFAService.startEnrollOrAuth({
      httpOptions: {
        headers: {
          Authorization: `Bearer ${this._token?.accessToken}`,
        },
      },
    });
  }

  public async login() {
    localStorage.clear(); // TODO: fix usage of localStorage: this is just a band-aid
    const webAuth = await this.getWebAuth();
    
    webAuth.authorize({ prompt: 'login', scope: SCOPE });
  }

  public async logout() {
    localStorage.clear(); // TODO: fix usage of localStorage: this is just a band-aid
    this.cookieService.deleteAll();
    
    this.letsMFAService.logout({});

    const logoutRedirectURL = this._token?.logoutRedirectUrl || (window as any).location.origin;

    await this.renewTokens();

    document.dispatchEvent(new CustomEvent('autoLogout'));

    // logout from auth0 - navigates to auth0 logout url
    const webAuth = await this.getWebAuth();
    
    
    webAuth.logout({
      // clientID: environment.auth0.clientID,
      returnTo: logoutRedirectURL,
    });
  }

  public get token(): Promise<Token> {
    // expire 30 seconds early - gives tokens a chance to complete network requests
    if (this._token && this._token.expiresAt > Date.now() + 30) {
      return Promise.resolve(this._token);
    }

    return this.renewTokens();
  }

  public get token$(): Observable<Token | undefined> {
    return this._token$;
  }

  private setToken(token: Token | undefined) {
    this._token = token;
    this._token$.next(token);
  }

  private sendPluginLoginMessage(token: Token) {
    const chrome: any = window['chrome'];
    if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
      chrome.runtime.sendMessage(environment.chromeExtensionId, { type: 'login', token: JSON.stringify(token) });
    }
  }

  private checkSession(options: CheckSessionOptions): Promise<any> {
    return new Promise(async (resolve, reject) => {

      const webAuth = await this.getWebAuth();

      webAuth.checkSession(options, (err, authResult) => {
        if (err) {
          return reject(err);
        }
        resolve(authResult);
      });
    });
  }

  private checkMFA(): Promise<CheckResponse> {

    return new Promise((resolve, reject) => {
      const headers: HttpHeaders | { [key: string]: string | string[] } = this._token
        ? {
            Authorization: `Bearer ${this._token.idToken}`,
          }
        : {};

      this.letsMFAService
        .checkValidationState({
          headers,
        })
        .subscribe({
          next: (state) => {
            if (state.validationState === 'validated') {
              resolve(state);
            } else {
              reject(state);
            }
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  // multiple sources may request renewTokens concurrently, ensure only a single
  // call to _renewTokens is active at a single time
  private _renewTokenRequest = new Subject<boolean>();
  private _singleConcurrentRenewToken$ = this._renewTokenRequest.pipe(
    exhaustMap(() => this._renewTokens()),
    share()
  );

  private renewTokens(): Promise<Token> {
    // create promise
    const promise = lastValueFrom(this._singleConcurrentRenewToken$.pipe(take(1)));

    // initiate _renewTokens request
    this._renewTokenRequest.next(true);

    return promise;
  }

  private async _renewTokens(): Promise<Token> {
    try {

      const environmentConfig = await this.configService.getEnvironmentConfigFromServer();

      const authResult = await this.checkSession({
        audience: environmentConfig.auth0.audience,
        scope: SCOPE,
        prompt: 'consent',
      });

      const token = this.handleSession(authResult);

      try {
        await this.checkMFA();
      } catch (err) {
        console.log(`MFA check failed: ${err}`);
        await this.login();
        throw err;
      }

      this.sendPluginLoginMessage(token);


      return token;
    } catch (err: any) {

      console.error(`Could not get a new token (${err.error}: ${err.error_description}).`);

      // TODO: add mechanism such as modal to present user with choice to not navigate away from
      // current page. This gives them the opportunity to try and save current state before login/logoff

      if (err.error === 'login_required') {
        await this.login();
      } else {
        if (err.description.includes('verify your email')) {
          this.router.navigate(['/verify-email']);
        } else 
          this.logout();
      }

      throw err;
    }
  }

  private handleSession(authResult: Auth0DecodedHash | null): Token {
    if (!authResult || !authResult.accessToken || !authResult.idToken || !authResult.expiresIn) {
      throw new Error('Invalid Auth Result');
    }

    const token: Token = {
      accessToken: authResult.accessToken,
      idToken: authResult.idToken,
      expiresAt: authResult.expiresIn * 1000 + new Date().getTime(),

      // For users from 3rd party auth identifiers (SAML, etc)
      // This is provided via a custom action named "Add Logout URL" in Auth0
      logoutRedirectUrl: authResult.idTokenPayload.logoutRedirectURL,
    };

    this.setToken(token);
    return token;
  }

  private async getWebAuth(): Promise<WebAuth> {

    if(!this._webAuth) {

      // Get the auth0 config from the /assets/auth0-config.json file
      // This file is generated by the build process and contains the
      // auth0 config values for the current environment

      const environmentConfig = await this.configService.getEnvironmentConfigFromServer();

      const auth0Domain = new URL(environmentConfig.auth0.issuer).hostname;

      const webAuth: WebAuth = new WebAuth({
        domain: auth0Domain,
        clientID: environmentConfig.auth0.clientID,
        audience: environmentConfig.auth0.audience,
        responseType: 'token id_token',
        redirectUri: `${window.location.origin}/callback`,
        scope: SCOPE,
      });

      this._webAuth = webAuth;

    }
    return this._webAuth;
  }

  

  public async updatePassword(email: string) {

    const environmentConfig = await this.configService.getEnvironmentConfigFromServer();

    const payload = {
      client_id: environmentConfig.auth0.clientID,
      email: email,
      connection: 'Username-Password-Authentication',
    };
    const httpOptions = { responseType: 'text' } as any;

    return new Promise<void>((resolve, reject) => {
      this.http
      .post(`${environmentConfig.auth0.issuer}dbconnections/change_password`, payload, httpOptions)
      .subscribe(
        (_success) => {
         resolve();
        },
        (error: HttpErrorResponse) => {
          console.error(error);
          reject(error);
        }
      );
    });
    
  }
}
