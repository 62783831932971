import { Injectable } from "@angular/core";
import { AuthService } from "../../auth0/services/auth.service";
import { LetsMFAService } from 'lets-mfa-angular';
import { take } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenValidatorService {

  private FIVE_MINUTES_IN_MS = 300_000;

  constructor(
    private authService: AuthService,
    private letMfaService: LetsMFAService
  ) {
  }

  public trackMfaState(): void {
    const intervalId = setInterval(() => {
      this.letMfaService.checkValidationState()
      .pipe(take(1))
      .subscribe({
        next: status => {
          if (status.validationState != "validated") {
            clearInterval(intervalId);
            this.authService.logout().then();
          }
        },
        error: () => {
          clearInterval(intervalId);
          this.authService.logout().then();
        }
      });
    }, this.FIVE_MINUTES_IN_MS);
  }

}
